<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title>
      Aggiorna Dettagli Lavorazione
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <Form
        :disabled="editFormDisabled"
        :title="null"
        hide-reset
        send-text="Salva Informazioni"
        @submit="onSubmit"
      >
        <v-alert v-if="!isAssignedToUser" type="info" prominent dense outlined>
          Prendi in carico la lavorazione per procedere
        </v-alert>
        <v-divider class="mb-6" />
        <v-row>
          <FormItem
            v-model="new_status"
            name="Stato Lavorazione"
            type="select"
            :values="statuses"
            rules="required"
            required
            :clearable="true"
            :cols="12"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
            :disabled="!isAssignedToUser"
            @input="new_resolution = null"
          />
        </v-row>
        <v-row>
          <FormItem
            v-model="new_resolution"
            name="Esito Lavorazione"
            type="select"
            :values="resolutions"
            rules="required"
            required
            :clearable="true"
            :cols="12"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
            :disabled="!isAssignedToUser"
          />
        </v-row>
        <v-row
        v-if="false">
          <FormItem
            v-model="customer_notes"
            name="Note Cliente"
            type="textarea"
            :cols="12"
            :sm="10"
            :md="8"
            :lg="8"
            :xl="8"
            :rows="5"
            required
          /> </v-row
        ><v-row>
          <FormItem
            v-model="operator_notes"
            name="Note Operatore"
            type="textarea"
            ::cols="12"
            :sm="10"
            :md="8"
            :lg="6"
            :xl="6"
            :rows="5"
            required
            :disabled="!isAssignedToUser"
          />
        </v-row>
      </Form>
    </v-card-text>
  </v-card>
</template>

<script>
import Form from '@components/forms/Form.vue'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketEditForm',
  components: { FormItem, Form },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapGetters('tickets', {
      isAssignedToUser: 'isAssignedToUser',
      isTicketBlocked: 'isTicketBlocked',
    }),
    ...mapGetters('ticketsStatuses', {
      statusesByContext: 'statusesByContext',
    }),
    ...mapGetters('ticketsResolutions', {
      resolutionsByContext: 'resolutionsByContext',
    }),
    ...mapFields([
      'customer_notes',
      'operator_notes',
      'new_status',
      'new_resolution',
      'activity.macro',
      'activity.category',
      'activity.subcategory',
      'date_appointment',
      'date_appointment_end',
      'partner_ticket_id',
      'partner_ticket_tracking',
    ]),

    statuses() {
      if (this.isTicketBlocked) {
        return this.statusesByContext({
          macro: this.activity.macro,
          category: this.activity.category,
          checkRole: true,
        }).filter(status => status.value === 'AGGIORNATA')
      }
      return this.statusesByContext({
        macro: this.activity.macro,
        category: this.activity.category,
        checkRole: true,
      }).filter(status => !status.system)
    },
    resolutions() {
      return this.resolutionsByContext({
        macro: this.activity.macro,
        category: this.activity.category,
        status: this.new_status,
        checkRole: true,
      }).filter(resolution => !resolution.system)
    },
    editFormDisabled() {
      return (
        !this.isAssignedToUser || !this.canUser('tickets', 'update')
        // || this.ticket.created_by !== this.beUsername
      )
    },
    isMagicLink() {
      return false
    }
  },
  watch: {
    status() {
      this.new_resolution = null
    },
  },
  mounted() {
    this.operator_notes = null;
  },
  methods: {
    ...mapMutations('tickets', {
      setCurrent: 'SET_CURRENT',
    }),
    ...mapActions('tickets', ['update']),
    async onSubmit() {
      try {
        this.setCurrent({ status: this.new_status, resolution: this.new_resolution });
        await this.update();
        this.operator_notes = null;
      } catch (err) {
        console.debug(err)
      }
    },
  },
}
</script>

<style scoped></style>
