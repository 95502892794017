var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TicketMoneyDetails',{staticClass:"my-3"}),_c('v-tabs',{attrs:{"background-color":"transparent"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-table-outline ")]),_vm._v(" Storico ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-table-outline ")]),_vm._v(" Piano di Rientro ")],1),_c('v-tab-item',[_c('BaseTable',{ref:"table",attrs:{"title":"Pagamenti","module":"economicDetail","headers":_vm.headers,"loading":_vm.loading,"elevation":0,"actions":_vm.actions,"sortby":"creation_date","sort-desc":false},scopedSlots:_vm._u([{key:"item.residual_total_debt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.residual_total_debt,'€'))+" ")]}},{key:"item.residual_capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.residual_total_debt - item.oca_refund_expected,'€'))+" ")]}},{key:"item.oca_refund_expected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.oca_refund_expected,'€'))+" ")]}},{key:"item.oca_fees_expected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.oca_fees_expected,'€'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount ? Math.abs(item.amount) : item.amount,'€'))+" "+_vm._s(!item.amount ? '-' : '')+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_method || '-')+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.payment_date,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.creation_date,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.payment.exported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.exportedByType(item))+" ")]}},{key:"item.payment.praticx_exported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.praticxExportedRemapped(item))+" ")]}},{key:"actions",fn:function(){return [(_vm.canUser('payments', 'create'))?_c('v-btn',{attrs:{"color":"green","dark":"","tile":"","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Inserisci pagamento ")]):_vm._e()]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"50%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.refresh},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.modalTitle))]),_c('v-divider'),_c('v-card-text',[_c('TicketPaymentsForm',{attrs:{"transfer":_vm.transfer},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)],1),_c('v-tab-item',[_c('TicketRecoveryPlanTable')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }