<template>
  <Form
    ref="form"
    send-text="OK"
    @submit="submit"
    @reset="reset"
  >
    <v-row dense>
      <v-col>
        <v-radio-group v-model="typeSelected" class="ma-0" hide-details="auto" dense>
          <v-radio
            v-for="(value, key) in typeList"
            :key="key"
            :label="value"
            :value="key"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>

        <FormItem
          v-model="installment.value"
          :name="typeSelected === 'numberInstallments' ? 'Num. rate' : 'Importo'"
          :cols="12"
          :md="12"
          :lg="12"
          :xl="12"
          type="number"
          dense
          class="ma-0 pa-1"
          :prepend-inner-icon="typeSelected === 'maxInstallment' ? 'mdi-currency-eur' : null"
          :step="typeSelected === 'numberInstallments' ? 1 : 0.01"
          rules="required"
          min="1"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <FormItem
        v-model="installment.firstDate"
        name="Data prima rata"
        type="date"
        dense
        :cols="8"
        :xl="8"
        :lg="8"
        rules="required"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="installment.each"
        name="Ogni"
        :cols="6"
        :md="4"
        :lg="4"
        :xl="4"
        type="number"
        dense
        rules="required"
        step="1"
        min="1"
      />
      <FormItem
        v-model="installment.period"
        name="Periodo"
        type="select"
        :values="periodList"
        dense
        :cols="8"
        :xl="8"
        :lg="8"
        rules="required"
      />
    </v-row>
  </Form>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import Form from '@components/forms/Form.vue'
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: "TicketRecoveryPlanForm",
  components: {Form, FormItem},
  data() {
    return {
      typeList: { numberInstallments: 'Numero rate', maxInstallment: 'Importo massimo rata' },
      periodList: [
        { value: 'month', text: 'Mesi' },
        { value: 'week', text: 'Settimane' },
      ],
      typeSelected: 'numberInstallments',
      installment: {
        value: null,
        each: null,
        period: null,
        firstDate: null,
      },
    };
  },
  computed: {
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('economicRecoveryPlan',['ctx']),
    ...mapGetters('externalData', ['getTotalDebt']),
  },
  methods: {
    ...mapActions('economicRecoveryPlan', ['createPlan', 'getItems']),
    async submit() {
      await this.createPlan({
        typeSelected: this.typeSelected,
        installment: {
          ...this.installment,
          value: Number(this.installment.value),
          each: Number(this.installment.each),
        },
        code: this.ticket.external_id,
        totalDebt: this.getTotalDebt,
      });
      this.$refs.form.clear();
      await this.getItems(this.ctx);
      this.$emit('close-form');
    },
    reset() {
      this.typeSelected = 'numberInstallments';
      Object.keys(this.installment).forEach((key) => { this.installment[key] = null });
    }
  },
}
</script>
