<template>
  <div
    ref="mapContainer"
    class="map-container"
    :style="{ 'z-index': 0, height, width }"
  >
    <l-map
      ref="map"
      :zoom="zoom"
      :options="mapOptions"
      :style="{ 'z-index': 0, height: '100%' }"
      :center="center"
      @update:bounds="boundsUpdated"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :lat-lng="[marker.lat, marker.lng]"
        :icon="marker.icon"
      >
        <l-popup v-if="popup">
          <slot :marker="marker"></slot>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
/* global L */

export default {
  name: 'Map',
  mixins: [], // maps
  props: {
    markers: {
      type: Array,
      required: false,
      default: () => [],
    },
    height: {
      type: [String, Number],
      required: false,
      default: '300px',
    },
    width: {
      type: [String, Number],
      required: false,
      default: '100%',
    },
    zoom: {
      type: [String, Number],
      required: false,
      default: 6,
    },
    updateCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    fitBounds: {
      type: Boolean,
      required: false,
      default: false,
    },
    popup: {
      type: [Boolean, Object],
      required: false,
      default: () => null,
    },
    center: {
      type: Array,
      required: false,
      default: () => [45.464211, 9.191383],
    },
  },
  data() {
    return {
      url:
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: 'TxT',
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
    }
  },
  computed: {
    computedCenter() {
      if (this.markers && this.markers.length === 1) {
        return L.latLng([this.markers[0].lat, this.markers[0].lng])
      } else {
        return L.latLng([45.464211, 9.191383])
      }
    },
    bounds() {
      let points
      if (this.markers.length) {
        points = this.markers.map((m) => [m.lat, m.lng])
      } else {
        points = [[45.464211, 9.191383]]
      }
      return L.latLngBounds(points)
    },
  },
  watch: {
    bounds: {
      immediate: true,
      handler(value) {
        if (this.fitBounds)
          this.$refs.map?.fitBounds(value, { padding: [150, 150] })
      },
    },
  },
  methods: {
    boundsUpdated(bounds) {
      this.$emit('drag', bounds)
      this.$emit('boundsUpdated', bounds)
    },
  },
}
</script>

<style>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.map-container {
  min-height: 200px;
}
</style>
