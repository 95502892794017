var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"table",attrs:{"module":"economicRecoveryPlan","title":"Piano di Rientro","headers":_vm.headers,"elevation":0,"sortby":['payment_received', 'payment_date_expected'],"sort-desc":false,"actions":_vm.actions},on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.created_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.payment_date_expected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.payment_date_expected,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.installment_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.installment_amount))+" ")]}},{key:"item.payment_received",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_received === false ? 'NO' : 'SI')+" ")]}},(_vm.canUser('economic_recovery_plan', 'create') && _vm.items.length === 0 && !_vm.isTicketBlocked)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"green","dark":"","tile":"","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Crea Piano di Rientro ")])]},proxy:true}:(_vm.canUser('economic_recovery_plan', 'create') && !_vm.isTicketBlocked)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"blue","dark":"","tile":"","depressed":""},on:{"click":_vm.removeRecoveryPlan}},[_vm._v(" Cancella Piano di Rientro ")])]},proxy:true}:null],null,true)}),_c('v-dialog',{attrs:{"max-width":"50%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Crea Piano di Rientro")]),_c('v-divider'),_c('v-card-text',[_c('TicketRecoveryPlanForm',{on:{"close-form":_vm.closeForm}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }