<template>
  <Form
    send-text="Assegna"
    :hide-reset="true"
    @submit="onSubmit"
  >
    <v-row class="my-3" dense>
      <v-select
        v-model="selectedUser"
        label="Seleziona un operatore"
        item-text="username"
        item-value="username"
        type="select"
        :items="items"
      />
    </v-row>
  </Form>
</template>

<script>
import Form from '@components/forms/Form.vue';
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'TicketAssigned',
  components: {
    Form,
  },
  data() {
    return {
      selectedUser: null,
    };
  },
  computed: {
    ...mapState('users', {
      items: (state) => state.pagination.rows,
    }),
  },
  async beforeMount() {
    await this.setFilters();
  },
  methods: {
    ...mapMutations('users', {
      setUsersFilters: 'SET_FILTERS',
    }),
    ...mapMutations('tickets', {
      updateCurrent: 'SET_CURRENT',
    }),
    ...mapActions('users', {
      getUsers: 'getItems',
    }),
    ...mapActions('tickets', ['update']),
    async setFilters() {
      this.setUsersFilters({ role_id: [5, 6] });
      this.getUsers({});
    },
    async onSubmit() {
      if (this.selectedUser !== null) {
        this.updateCurrent({
          managed_by: this.selectedUser,
        })
        await this.update();
        this.$emit('submitted');
      } else this.$emit('submitted');
    },
  }
};
</script>
