<template>
  <v-card elevation="0" outlined tile>
    <Map
      v-if="$vuetify.breakpoint.mdAndUp && ticket.type === 'onsite'"
      height="260px"
      :markers="markers"
      fit-bounds
    />
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">Identificativo</small>
          {{ ticket.external_id }}

          <small class="d-block text--secondary mr-5 mt-4">Attività</small>
          {{ activity.macro }} <v-icon>mdi-chevron-right</v-icon>
          {{ activity.category }} <v-icon>mdi-chevron-right</v-icon>
          {{ activity.subcategory }}
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary mr-5">Debitore</small>
          {{ customer.common_name }}
          <small class="mr-4"></small>
          <v-btn
            v-if="!isMagicLink"
            icon
            small
            color="primary"
            :to="{
              name: 'customers_detail',
              params: { id: customer.id },
            }"
            ><v-icon>mdi-magnify</v-icon>
          </v-btn>

          <small class="d-block text--secondary">
            {{ customer.address }} - {{ customer.cap }} {{ customer.city }} ({{
              customer.province
            }}) - {{ customer.country }}
          </small>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary mr-5">Contatti</small>
          <small
            v-if="customer.email"
            class="d-block text-body-1 mt-2 font-weight-medium"
          >
            <v-icon small left>mdi-email-outline</v-icon>
            {{ customer.email }}
          </small>
          <small
            v-if="customer.mobile_phone_number"
            class="d-block text-body-1"
          >
            <v-icon small left>mdi-phone</v-icon>
            {{ customer.mobile_phone_number | phoneNumber }}
          </small>
          <small v-if="customer.work_phone_number" class="d-block text-body-1">
            <v-icon small left>mdi-phone-classic</v-icon>
            {{ customer.work_phone_number | phoneNumber }}
          </small>
          <small v-if="customer.alt_phone_number" class="d-block text-body-1">
            {{ customer.alt_phone_number | phoneNumber }}
          </small>
          <small v-if="customer.alt_phone_number_2" class="d-block text-body-1">
            {{ customer.alt_phone_number_2 | phoneNumber }}
          </small>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">Debito totale residuo</small>
          {{
            (getTotalDebt >= 0 ? getTotalDebt : 0)
              | currency('€', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}

          <small class="d-block text--secondary">
            di cui spese Nivi
            {{
              externalData.spese_attese >= 0 ? externalData.spese_attese : 0
                | currency('€', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </small>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text class="subtitle-1">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary"> Stato </small>
          <v-chip label color="primary">
            {{ ticket.status | placeholder('-') }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">Azione</small>
          <v-chip label>{{ ticket.resolution | placeholder('-') }}</v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <small class="d-block text--secondary">
            Data inserimento
          </small>
          <v-chip label>
            {{ ticket.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="3">
            <small class="d-block text--secondary">Data aggiornamento</small>
            <v-chip label>
              {{ ticket.updated_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
            </v-chip>
          </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapState } from 'vuex'
import Map from '@components/common/Map.vue'
import utils from '@mixins/utils.js'

const { mapFields: mapTicketFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketDetails',
  components: {
    Map,
  },
  mixins: [utils],
  data() {
    return {
      activities: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
      assets: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
      customer_users: {
        dialog: false,
        isUpdating: false,
        value: null,
      },
      customers: {
        dialog: false,
        isUpdating: false,
        value: null,
      },
      subscriptions: {
        dialog: false,
        hasSubmitted: false,
        isUpdating: false,
        value: null,
      },
      isMagicLink: false,
    }
  },
  computed: {
    ...mapGetters('externalData', ['getTotalDebt']),
    ...authComputed,
    ...mapTicketFields(['activity_id', 'asset_id', 'customer_id']),
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('externalData', {
      getCurrentExternalData: 'current',
    }),
    externalData() {
      return {
        'capitale_debito': this.getCurrentExternalData?.capitale_debito,
        'spese_attese': this.getCurrentExternalData?.spese_attese,
      }
    },
    markers() {
      return [this.location]
    },
  },
  methods: {
    ...mapActions('tickets', {
      setCurrentTicket: 'setCurrent',
      getRelatedData: 'getCurrentRelatedData',
      updateTicket: 'update',
    }),
    ...mapActions('customers', {
      setCurrentCustomer: 'setCurrent',
    }),
    ...mapActions('ticketsActivities', {
      setCurrentActivity: 'setCurrent',
    }),

    async updateActivity() {
      this.activities.isUpdating = true
      this.activity_id = this.activities.value
      await this.updateTicket()
      await this.setCurrentActivity(this.activity_id)
      if (this.customers.isUpdating) {
        this.activities.hasSubmitted = true
      }
      this.activities.dialog = false
      this.activities.value = null
      this.activities.isUpdating = false
    },

    async updateCustomer() {
      this.customers.isUpdating = true

      const res = await this.$dialog.confirm({
        text: `Cambiando il cliente associato, se presente, l'utente associato verrà rimosso.
              In aggiunta sarà richiesto di inserire i dati necessari, continuare?`,
        title: 'Richiesta conferma',
      })
      if (!res) {
        this.customers.isUpdating = false
        return
      }

      /**
       * If the current activity is bound check is coherent or select a coherent one
       */
      if (this.activity.customer_id) {
        if (this.activity.customer_id !== this.customers.value) {
          this.customer_id = this.customers.value

          // If he hasn't already force the user to select a new activity
          if (!this.activities.value) {
            this.activities.dialog = true
            const canProceed = await this.waitForActivity()
            if (!canProceed) {
              this.$dialog.notify.error(`Processo interrotto`, {
                timeout: 2000,
              })
              this.activities.dialog = true
              this.activities.value = null
              this.customers.isUpdating = false
              this.customers.dialog = false
              return
            }
          }
        }
      }

      this.customer_id = this.customers.value
      await this.updateTicket()
      await this.setCurrentCustomer(this.customer_id)
      this.customers.value = null
      this.customers.dialog = false
      this.customers.isUpdating = false
    },

    waitForActivity() {
      return new Promise((resolve) => {
        let count = 0
        const interval = setInterval(() => {
          if (this.activities.hasSubmitted) {
            this.activities.hasSubmitted = false
            clearInterval(interval)
            resolve(true)
            // Wait two minutes or wait until the user closes the modal without selecting
          } else if (count === 240 || !this.activities.dialog) {
            clearInterval(interval)
            resolve(false)
          } else {
            count++
          }
        }, 500)
      })
    },
  },
}
</script>

<style scoped></style>
