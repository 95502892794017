<template>
  <v-card outlined tile elevation="0" class="mt-5">
    <v-card-title>
      Riepilogo Situazione Debitoria
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col :cols="cols" :class="{ 'text-right': vertical }">
          <span class="d-block text--secondary">Debito totale residuo</span>
          <span class="text-h5 font-weight-bold ">
            {{ (getTotalDebt >= 0 ? getTotalDebt : 0) | currency }}
          </span>
        </v-col>
        <v-col :cols="cols" :class="{ 'text-right': vertical }">
          <span class="d-block text--secondary">di cui Quota Capitale</span>
          <span class="text-h5 font-weight-medium">
            {{ externalData.capitale_debito | currency }}
          </span>
        </v-col>
        <v-col :cols="cols" :class="{ 'text-right': vertical }">
          <span class="d-block text--secondary">di cui Spese Nivi</span>
          <span class="text-h5 font-weight-medium">
            {{ externalData.spese_attese >= 0 ? externalData.spese_attese : 0 | currency }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TicketMoneyDetails',
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('externalData', ['getTotalDebt']),
    cols() {
      if (this.vertical) return 12
      return 3
    },
    ...mapState('externalData', {
      getCurrentExternalData: 'current',
    }),
    externalData() {
      return {
        'capitale_debito' : this.getCurrentExternalData?.capitale_debito,
        'spese_attese': this.getCurrentExternalData?.spese_attese,
      }
    },
  },
}
</script>
