<template>
  <v-container fluid>
    <TicketDetails v-if="ticket.id" />
    <TicketActions v-if="ticket.id" />
    <TicketNotifications v-if="ticket.id" />
    <TicketBody v-if="ticket.id" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TicketDetails from '@components/tickets/TicketDetails.vue'
import TicketActions from '@components/tickets/TicketActions.vue'
import TicketBody from '@components/tickets/TicketBody.vue'
import storeReset from '@mixins/storeReset'
import TicketNotifications from '@components/tickets/TicketNotifications.vue'

export default {
  name: 'TicketDetailView',
  page: {
    title: 'Dettaglio Ticket',
  },
  components: { TicketNotifications, TicketBody, TicketActions, TicketDetails },
  mixins: [storeReset],
  computed: {
    ...mapState('tickets', {
      ticket: 'current',
    }),
  },
  async mounted() {
    await this.setCurrent(this.$route.params.id);
    await this.getRelatedData();
    this.$root.$on('ticket-updated', async() => {
      await this.resetCurrent();
      await this.setCurrent(this.$route.params.id);
      await this.getRelatedData();
    });
  },
  beforeDestroy() {
    this.storeReset({
      // Reset the ticket itself
      tickets: ['current'],

      // And its related data
      customers: ['current'],
      ticketsActivities: ['current'],
      ticketsHistory: ['paginated'],
      externalData: ['current'],
    })
  },
  methods: {
    ...mapActions('tickets', {
      resetCurrent: 'resetCurrent',
      setCurrent: 'setCurrent',
      getRelatedData: 'getCurrentRelatedData',
    }),
  },
}
</script>

<style>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
