<template>
  <v-row>
    <v-col cols="9">
      <BaseTable
        title="Dettagli Pratica"
        :headers="headers"
        :items="items"
        :total-rows="countItems"
        :actions="actions"
        :loading="loading"
        :elevation="0"
        group-by="tipo"
        :show-group-by="false"
        sortby="group"
        hide-default-footer
      >
        <template v-slot:group.header="{ group }">
          <td colspan="3"> {{ group }}</td>
        </template>
      </BaseTable>
    </v-col>
    <v-col>
      <TicketMoneyDetails vertical />
    </v-col>
  </v-row>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import TicketMoneyDetails from "@components/tickets/ebay/TicketMoneyDetails.vue"

export default {
  name: 'TicketDataTable',
  components: {TicketMoneyDetails, BaseTable },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Gruppo',
          value: 'tipo',
          groupable: true,
        },
        {
          text: 'Descrizione',
          value: 'label',
          width: '40%',
          cellClass: 'font-weight-bold',
        },
        {
          text: 'Valore',
          value: 'value',
        },
      ],
      actions: [
        {
          key: 'update',
          getLabel: (item) => item.extra?.badPhone ? 'Rimuovi bad phone' : 'Imposta bad phone',
          getIcon: (item) => item.extra?.badPhone ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline',
          color: 'transparent',
          button: true,
          tipo: 'Anagrafica',
          onItemCondition: (item) => ['Mobile', 'Fax', 'Fisso'].includes(item.label),
          handler: this.switchBadPhone,
          hide: () => !this.canUser('ebay_placement_debt_fn', 'update') || this.ticket.status === 'RECALL',
        },
      ],
    };
  },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapState('externalData', {
      getCurrentExternalData: 'current',
    }),
    items() {
      return this.getCurrentExternalData?.items || []
    },
    countItems() {
      return this.items.length;
    }
  },
  methods: {
    ...mapActions('placementDebtFn', ['changeBadPhone']),
    ...mapActions('externalData', ['readData']),
    async switchBadPhone(item) {
      const res = await this.$dialog.confirm({
        text: `Sicuro di voler ${item.extra?.badPhone ? 'rimuovere' : 'impostare'} il numero come bad phone?`,
        title: 'Attenzione',
      });
      if (!res) return;

      await this.changeBadPhone({
        "type": this.activity.category,
        "idRifNivi": this.ticket.external_id,
        "label": item.label,
        "badPhone": !item.extra?.badPhone,
      });

      await this.readData();
    }
  },
}
</script>

<style scoped></style>
