<template>
  <Form
    send-text="OK"
    :loading="loading"
    :hide-reset="payment.id"
    @submit="onPaymentSubmit"
    @reset="onPaymentReset"
  >
    <v-row class="my-3" dense>
      <FormItem
        v-model="amount"
        name="Importo"
        type="number"
        placeholder="1920,00"
        dense
        rules="required"
        prepend-inner-icon="mdi-currency-eur"
        :cols="12"
        :md="8"
        :xl="8"
        :lg="8"
        :disabled="setTotalSelected || transfer"
        step="0.01"
      />
      <v-col :md="4" :xl="4" :lg="4">
        <v-checkbox v-model="checked" :disabled="transfer" dense label="Integrale" @change="onCheckboxChange($event)" />
      </v-col>

      <FormItem
        v-model="payment_date"
        type="datepicker"
        :cols="12"
        :xl="12"
        :lg="12"
        rules="required"
        maxToday
        name="Data Pagamento"
      />
    </v-row>
  </Form>
</template>

<script>
import moment from 'moment-timezone';
import FormItem from '@components/forms/FormItem.vue';
import Form from '@components/forms/Form.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {createHelpers} from "vuex-map-fields";
import { fixedCurrency } from '@utils/formdata';


const { mapFields } = createHelpers({
  getterType: 'payments/getCurrent',
  mutationType: 'payments/SET_CURRENT_FIELDS',
})

export default {
  name: "TicketPaymentsForm",
  components: {Form, FormItem},
  props: {
    transfer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    setTotalSelected: false,
    checked: false,
  }),
  computed: {
    ...mapGetters('externalData', ['getTotalDebt']),
    ...mapFields(['ticket_id', 'annotation', 'amount', 'payment_type', 'payment_date', 'country']),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('payments', {
      loading: (state) => state.loading,
      payment: 'current',
    }),
  },
  mounted() {
    if (!this.payment.id) this.payment_date = moment();
    this.$root.$on('reset-payment', () => {
      this.checked = false;
      this.setTotalSelected = false;
    });
  },
  methods: {
    ...mapActions('payments', {
      createPayment: 'create',
      updatePayment: 'update',
    }),
    ...mapMutations('payments', {
      setCurrentPayment: 'SET_CURRENT',
      resetPayment: 'RESET_CURRENT',
    }),
    async onPaymentSubmit() {
      try {
        const amount = fixedCurrency(this.amount);
        if (amount > this.getTotalDebt + 10 + this.payment?.old_amount && !this.transfer) {
          const res = await this.$dialog.confirm({
            text: "Si sta inserendo un pagamento con eccedenza. L'eccedenza sarà registrata nella sezione \"Non riconciliabili o eccedenze\".\nProseguire con il pagamento?",
            title: 'Attenzione',
          });

          if (!res) return;
        } else if (amount > this.getTotalDebt + this.payment?.old_amount && !this.transfer) {
          const res = await this.$dialog.confirm({
            text: "Si sta rinserendo un pagamento con eccedenza inferiore alla tolleranza di 10€, tale eccedenza verrà registrata insieme agli oneri di esazione.\nProseguire con il pagamento?",
            title: 'Attenzione',
          });

          if (!res) return;
        }
          if (this.payment.id) {
            if (this.transfer) {
              const res = await this.$dialog.confirm({
                text: "Proseguire con lo storno?\nL'azione non è reversibile!",
                title: 'Attenzione',
              });

              if (!res) return;
            }

            await this.updatePayment();
          } else {
            const paymentToPass = { ticket_id: this.customer.code, amount, payment_type: 1, payment_date: this.payment_date, country: this.customer.country };
            if (this.getTotalDebt <= 0.00) paymentToPass.payment_type = 3;
            this.setCurrentPayment(paymentToPass);
            await this.createPayment();
          }
          this.resetPayment();
          if (!this.payment.id) this.payment_date = moment();
          this.$emit('submitted');
      } catch (err) {
        console.log('errore', err)
      }
    },
    onPaymentReset() {
      const oldAmount = this.payment?.old_amount || 0;
      this.resetPayment();
      this.payment_date = moment();
      this.setCurrentPayment({ old_amount: oldAmount });
    },
    onCheckboxChange(checked) {
      this.checked = checked;
      if (checked) {
        this.setCurrentPayment({ amount: this.getTotalDebt });
        this.setTotalSelected = true;
      } else {
        this.setTotalSelected = false;
      }
    },
  },
}
</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }
</style>
