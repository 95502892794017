<template>
  <div>
    <BaseTable
      ref="table"
      module="economicRecoveryPlan"
      title="Piano di Rientro"
      :headers="headers"
      :elevation="0"
      :sortby="['payment_received', 'payment_date_expected']"
      :sort-desc="false"
      :actions="actions"
      @refresh="refresh"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.payment_date_expected="{ item }">
        {{ item.payment_date_expected | momentOr('DD/MM/YYYY', '-') }}
      </template>
      <template v-slot:item.installment_amount="{ item }">
        {{ item.installment_amount | currency }}
      </template>
      <template v-slot:item.payment_received="{ item }">
        {{ item.payment_received === false ? 'NO' : 'SI' }}
      </template>
      <template v-if="canUser('economic_recovery_plan', 'create') && items.length === 0 && !isTicketBlocked" v-slot:actions>
        <v-btn
          color="green"
          dark
          tile
          depressed
          @click="dialog = true"
        >
          Crea Piano di Rientro
        </v-btn>
      </template>
      <template v-else-if="canUser('economic_recovery_plan', 'create') && !isTicketBlocked" v-slot:actions>
        <v-btn
          color="blue"
          dark
          tile
          depressed
          @click="removeRecoveryPlan"
        >
          Cancella Piano di Rientro
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="50%"
      :fullscreen="$vuetify.breakpoint.mobile"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>Crea Piano di Rientro</v-card-title>
        <v-divider />
        <v-card-text>
          <TicketRecoveryPlanForm @close-form="closeForm" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import TicketRecoveryPlanForm from "@components/tickets/ebay/TicketRecoveryPlanForm.vue";
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import { authComputed } from '@state/helpers';

export default {
  name: 'TicketPaymentsDetails',
  components: { BaseTable, TicketRecoveryPlanForm },
  data() {
    return {
      headers: [
        {
          text: 'Data e ora Inserimento',
          value: 'created_at',
        },
        {
          text: 'Importo',
          value: 'installment_amount',
        },
        {
          text: 'Num Rata',
          value: 'installment_number',
        },
        {
          text: 'Pagamento atteso',
          value: 'payment_date_expected',
        },
        {
          text: 'Pagamento ricevuto',
          value: 'payment_received',
        },
      ],
      dialog: false,
      actions: [
        {
          label: 'Imposta pagamento ricevuto',
          color: 'primary',
          onItemCondition: (item) => !item.payment_received && !this.isTicketBlocked,
          handler: async (item) => this.changePaymentReceived(item),
        },
        {
          label: 'Rimuovi pagamento ricevuto',
          color: 'warning',
          onItemCondition: (item) => item.payment_received && !this.isTicketBlocked,
          handler: (item) => this.changePaymentReceived(item),
        },
      ],
    };
  },
  computed: {
    ...authComputed,
    ...mapState('economicRecoveryPlan', {
      items: (state) => state.pagination.rows,
      filters: (state) => state.filters,
    }),
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapGetters('tickets', {
      isTicketBlocked: 'isTicketBlocked',
    }),
  },
  beforeMount() {
    this.setFilters({ code: this.ticket.external_id });
  },
  methods: {
    ...mapMutations('economicRecoveryPlan', {
      setCtx: 'SET_CTX',
      setCurrent: 'SET_CURRENT',
      setFilters: 'SET_FILTERS',
    }),
    ...mapActions('economicRecoveryPlan', ['createPlan', 'getItems', 'update', 'removeItems']),
    closeForm() {
      this.dialog = false;
    },
    refresh(ctx) {
      this.setCtx(ctx);
    },
    async changePaymentReceived(item) {
      this.setCurrent({ ...item, payment_received: !item.payment_received });
      await this.update();
      this.getItems();
    },
    async removeRecoveryPlan() {
      const res = await this.$dialog.confirm({
        text: "Si sta cancellando il piano di rientro, proseguire con la cancellazione?",
        title: 'Attenzione',
      });

      if (!res) return;
      await this.removeItems(this.filters);
      this.getItems(this.filters);
    }
  }
}
</script>
