<template>
  <div>
    <TicketMoneyDetails class="my-3"/>

    <v-tabs background-color="transparent">

      <v-tab>
        <v-icon left> mdi-file-table-outline </v-icon>
        Storico
      </v-tab>
      <v-tab>
        <v-icon left> mdi-file-table-outline </v-icon>
        Piano di Rientro
      </v-tab>
      <v-tab-item>
        <BaseTable
          ref="table"
          title="Pagamenti"
          module="economicDetail"
          :headers="headers"
          :loading="loading"
          :elevation="0"
          :actions="actions"
          sortby="creation_date"
          :sort-desc="false"
        >
          <template v-slot:item.residual_total_debt="{ item }">
            {{ item.residual_total_debt | currency('€') }}
          </template>
          <template v-slot:item.residual_capital="{ item }">
            {{ item.residual_total_debt - item.oca_refund_expected | currency('€') }}
          </template>
          <template v-slot:item.oca_refund_expected="{ item }">
            {{ item.oca_refund_expected | currency('€') }}
          </template>
          <template v-slot:item.oca_fees_expected="{ item }">
            {{ item.oca_fees_expected | currency('€') }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount ? Math.abs(item.amount) : item.amount | currency('€') }}
            {{ !item.amount ? '-' : '' }}
          </template>
          <template v-slot:item.payment_method="{ item }">
            {{ item.payment_method || '-' }}
          </template>
          <template v-slot:item.payment_date="{ item }">
            {{ item.payment_date | momentOr('DD/MM/YYYY', '-') }}
          </template>
          <template v-slot:item.creation_date="{ item }">
            {{ item.creation_date | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </template>
          <template v-slot:item.payment.exported="{ item }">
            {{ exportedByType(item) }}
          </template>
          <template v-slot:item.payment.praticx_exported="{ item }">
            {{ praticxExportedRemapped(item) }}
          </template>
          <template v-slot:actions>
            <v-btn
              v-if="canUser('payments', 'create')"
              color="green"
              dark
              tile
              depressed
              @click="dialog = true"
            >
              Inserisci pagamento
            </v-btn>
          </template>
        </BaseTable>
        <v-dialog
          v-model="dialog"
          max-width="50%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
          @click:outside="refresh"
        >
          <v-card>
            <v-card-title>{{ modalTitle }}</v-card-title>
            <v-divider />
            <v-card-text>
              <TicketPaymentsForm :transfer="transfer" @submitted="onSubmitted" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item>
        <TicketRecoveryPlanTable />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import TicketMoneyDetails from "@components/tickets/ebay/TicketMoneyDetails.vue";
import TicketPaymentsForm from "@components/tickets/ebay/TicketPaymentsForm.vue";
import TicketRecoveryPlanTable from "@components/tickets/ebay/TicketRecoveryPlanTable.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {authComputed} from "@state/helpers";
import moment from "moment-timezone";

export default {
  name: 'TicketPaymentsDetails',
  components: { TicketMoneyDetails, TicketPaymentsForm, TicketRecoveryPlanTable, BaseTable },
  data() {
    return {
      headers: [
        {
          text: 'Data e ora Inserimento',
          value: 'creation_date',
        },
        {
          text: 'Origine',
          value: 'managed_by',
        },
        {
          text: 'Tipologia dato',
          value: 'update_type',
        },
        {
          text: 'Debito totale residuo',
          value: 'residual_total_debt',
        },
        {
          text: 'Di cui capitale residuo',
          value: 'residual_capital',
        },
        {
          text: 'Di cui spese residue',
          value: 'oca_refund_expected',
        },
        {
          text: 'Di cui commissioni residue',
          value: 'oca_fees_expected',
        },
        {
          text: 'Data pagamento',
          value: 'payment_date',
        },
        {
          text: 'Metodo pagamento',
          value: 'payment_method',
        },
        {
          text: 'Importo',
          value: 'amount',
        },
        {
          text: 'Contabilizzato',
          value: 'payment.exported'
        },
        {
          text: 'Inviato a Praticx',
          value: 'payment.praticx_exported',
        },
      ],
      actions: [
        {
          key: 'edit',
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: (item) => {
            return this.canUser('payments', 'update')
              && item.payment && item.payment.exported === 'new'
              && item.payment.praticx_exported === null
              && !item.payment.reversed && item.payment.payment_type === 1;
          },
          handler: this.editPayment,
        },
        {
          key: 'remove',
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: (item) => {
            return this.canUser('payments', 'delete')
              && item.payment && item.payment.exported === 'new'
              && item.payment.praticx_exported === null
              && ((!item.payment.reversed && item.payment.payment_type === 1) || item.payment.payment_type === 2);
          },
          handler: this.removePayment,
        },
        {
          key: 'register_storno',
          label: 'Registra storno',
          color: 'primary',
          onItemCondition: (item) => {
            return this.canUser('payments', 'update')
              && item.payment && (item.payment.exported !== 'new' || item.payment.praticx_exported !== null)
              && !item.payment.reversed && item.payment.payment_type === 1;
          },
          handler: this.paymentTransfer,
        },
      ],
      loading: false,
      dialog: false,
      transfer: false,
      modalTitle: 'Inserisci Pagamento',
      refreshMoneyDetails: false,
    };
  },
  computed: {
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...authComputed,
  },
  beforeMount() {
    this.setFilters();
    this.setScopes(['payment']);
  },
  methods: {
    ...mapMutations('economicDetail', {
      setPaymentFilters: 'SET_FILTERS',
      setScopes: 'SET_SCOPES',
    }),
    ...mapMutations('payments', {
      setCurrentPayment: 'SET_CURRENT',
      resetPayment: 'RESET_CURRENT',
    }),
    ...mapActions('payments', {
      removePaymentItem: 'removeItem',
      updatePaymentItem: 'update',
    }),
    ...mapActions('tickets', {
      getRelatedData: 'getCurrentRelatedData',
    }),
    setFilters() {
      this.setPaymentFilters({ code: this.customer.code });
    },
    async onSubmitted() {
      this.dialog = false;
      this.transfer = false;
      this.setCurrentPayment({ payment_date: moment(), old_amount: 0 });
      this.modalTitle = 'Inserisci Pagamento';
      this.$refs.table.refresh();
      await this.$root.$emit('ticket-updated');
    },
    refresh() {
      this.resetPayment();
      this.transfer = false;
      this.setCurrentPayment({ payment_date: moment(), old_amount: 0 });
      this.modalTitle = 'Inserisci Pagamento';
      this.$refs.table.refresh();
      this.$root.$emit('reset-payment');
    },
    async editPayment(item) {
      const paymentToPass = { id: item.payment.id, ticket_id: item.code, payment_type: item.payment.payment_type, amount: item.amount, payment_date: item.payment_date, invoiced: item.invoiced, old_amount: item.amount, country: item.payment.country };
      this.setCurrentPayment(paymentToPass);
      this.modalTitle = 'Modifica Pagamento';
      this.dialog = true;
    },
    async paymentTransfer(item) {
      if (item.payment) {
        const paymentToPass = { id: item.payment.id, ticket_id: item.code, payment_type: 2, amount: item.amount, payment_date: item.payment_date, country: item.payment.country };
        this.setCurrentPayment(paymentToPass);

        this.transfer = true;
        this.modalTitle = 'Storna';
        this.dialog = true;
      }
    },
    async removePayment(item) {
      if (item.payment) {
        const res = await this.$dialog.confirm({
          text: "Proseguire con l'eliminazione?\nL'azione non è reversibile!",
          title: 'Attenzione',
        });

        if (!res) return;

        await this.removePaymentItem(item.payment.id);
        await this.$refs.table.refresh();
        await this.$root.$emit('ticket-updated');
      }
    },
    exportedByType(item) {
      if (!item.payment) return '-';
      if ([1, 2].includes(item.payment.payment_type)) return item.payment.exported === 'exported' ? 'Si' : 'No';
      return 'N/A';
    },
    praticxExportedRemapped(item) {
      if (!item.payment) return '-';
      switch(item.payment.praticx_exported) {
        case 'exported':
        case 'progress':
          return 'Si';
        case 'new':
          return 'In fase di invio';
        default:
          return 'No';
      }
    },
  },
}
</script>

