<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title>
      Aggiorna Informazioni Recall
    </v-card-title>
    <v-card-text>
      <Form
        send-text="Salva Informazioni"
        hide-reset
        :disabled="updateScrubReasonIsDisabled"
        @submit="update"
      >
        <v-row>
          <FormItem
            v-model="scrubRsnId"
            name="Scrub Reason"
            type="select"
            :values="scrubReasonList"
            required
            :disabled="current.exported !== 'new'"
            :cols="12"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
          />
        </v-row>
      </Form>
    </v-card-text>
  </v-card>
</template>

<script>
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ScrubReasonEditForm',
  components: { FormItem, Form },
  props: {
    category: {
      type: String,
      default: null,
    },
    idRifNivi: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      baseUrl: this.category === 'FN' ? 'recallDebtRecordsFn' : 'recallDebtRecordsMp',
    };
  },
  computed: {
    ...mapState('scrubReason', ['stats']),
    ...mapState({
      current(state) {
        return state[this.baseUrl].current;
      }
    }),
    scrubReasonList() {
      return this.stats?.reduce((acc, el) => {
        acc.push({ text: el.scrub_reason, value: el.scrub_rsn_id });
        return acc;
      }, []) || [];
    },
    scrubRsnId: {
      set(value) {
        this.setCurrentField({ key: 'scrub_rsn_id', value });
      },
      get() {
        return this.current.scrub_rsn_id;
      },
    },
    updateScrubReasonIsDisabled() {
      return !this.current.scrub_rsn_id || this.current.exported !== 'new';
    },
  },
  async beforeMount() {
    this.setFilters({ id_rif_nivi: this.idRifNivi });
    await this.searchRecallItem();
    await this.getScrubReason();
  },
  methods: {
    ...mapActions('scrubReason', { getScrubReason: 'getStats' }),
    ...mapActions({
      searchRecallItem(dispatch, payload) {
        return dispatch(`${this.baseUrl}/setCurrentBySearch`, payload);
      },
      update(dispatch, payload) {
        return dispatch(`${this.baseUrl}/update`, payload)
      }
    }),
    ...mapMutations({
      setFilters(commit, payload) {
        return commit(`${this.baseUrl}/SET_FILTERS`, payload);
      },
      setCurrentField(commit, payload) {
        return commit(`${this.baseUrl}/SET_CURRENT_FIELD`, payload);
      }
    }),
  },
}
</script>

<style scoped></style>
