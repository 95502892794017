const mutationsMap = {
  current: 'RESET_CURRENT',
  filters: 'RESET_FILTER_FIELDS',
  list: 'RESET_LIST',
  paginated: 'RESET_PAGINATED_ITEMS',
  scopes: 'RESET_SCOPES',
}

export default {
  methods: {
    /**
     * Config has this shape eg
     * {
     *   customers: ['current', 'list', 'filters']
     * }
     */
    storeReset(config = {}) {
      for (const targetModule in config) {
        for (const target of config[targetModule]) {
          const actionName = `${targetModule}/${mutationsMap[target]}`
          this.$store.commit(actionName)
        }
      }
    },
  },
}
