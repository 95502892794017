<template>
  <div>
    <v-tabs background-color="transparent">

      <v-tab key="detail">
        <v-icon left> mdi-file-table-outline </v-icon>
        Dettagli Pratica
      </v-tab>

      <v-tab v-if="ticketStatusMenuIsEnabled" key="processing">
        <v-icon left> mdi-account </v-icon>
        Lavorazione Pratica
      </v-tab>

      <v-tab key="economic_detail">
        <v-icon left>mdi-currency-eur</v-icon>
        Dettaglio Economico
      </v-tab>

      <v-tab v-if="canUser('customers.attachments', 'search')" key="attachments">
        <v-icon left> mdi-file-star-outline </v-icon>
        Allegati
      </v-tab>

      <v-tab v-if="canUser('tickets_history', 'search')" key="updates">
        <v-icon left> mdi-access-point </v-icon>
        Aggiornamenti
      </v-tab>

      <v-tab v-if="showAdditionalFields" key="additional_fields">
        <v-icon left>mdi-playlist-check</v-icon>
        Campi aggiuntivi
      </v-tab>

      <v-tab v-if="recallMenuIsEnabled" key="recall">
        <v-icon left>mdi-cancel</v-icon>
        Recall
      </v-tab>

      <v-tab v-if="associatedTickets.length > 0" key="associated_tickets">
        <v-icon left>mdi-format-list-bulleted</v-icon>
        Pratiche associate
      </v-tab>

      <v-tab-item key="detail">
        <TicketDataTable />
      </v-tab-item>

      <v-tab-item v-if="ticketStatusMenuIsEnabled" key="processing">
        <TicketEditForm />
      </v-tab-item>

      <v-tab-item key="economic_detail">
        <TicketPaymentsDetails />
      </v-tab-item>

      <v-tab-item v-if="canUser('customers.attachments', 'search')" key="attachments">
        <CustomerAttachments
          show-tickets
        />
      </v-tab-item>

      <v-tab-item v-if="canUser('tickets_history', 'search')" key="updates">
        <TicketHistoryTable />
      </v-tab-item>

      <v-tab-item v-if="showAdditionalFields" key="additional_fields">
        <TicketShowAdditionalFields />
      </v-tab-item>

      <v-tab-item v-if="recallMenuIsEnabled" key="recall">
        <ScrubReasonEditForm :category="activity.category" :id-rif-nivi="ticket.external_id" />
      </v-tab-item>

      <v-tab-item v-if="associatedTickets.length > 0" key="associated_tickets">
        <TicketListFiltered :show-select="false" />
      </v-tab-item>
    </v-tabs>

    <v-row v-if="false">
      <v-col>
        <v-list flat>
          <v-list-item-title>Dettagli Ticket</v-list-item-title>
          <ListItem subtitle="Data Inserimento">
            {{ ticket.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Data Ingaggio">
            {{ ticket.received_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Inserito da">
            {{ ticket.created_by | placeholder('-') }}
          </ListItem>

          <ListItem subtitle="Data Presa in carico">
            {{ ticket.managed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="In carico all'operatore">
            {{ ticket.managed_by | placeholder('-') }}
          </ListItem>

          <ListItem
            v-if="ticket.kasko_closed_at"
            subtitle="Data Consegna Sostitutivo"
          >
            {{ ticket.kasko_closed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem
            v-if="ticket.kasko_tobe_closed_by"
            subtitle="Consegna Sostitutivo Entro"
          >
            {{
              ticket.kasko_tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-')
            }}
          </ListItem>

          <ListItem subtitle="Data Chiusura">
            {{ ticket.closed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </ListItem>
          <ListItem subtitle="Chiuso dall'operatore">
            {{ ticket.closed_by | placeholder('-') }}
          </ListItem>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import ListItem from '@components/structure/lists/ListItem.vue'
import TicketEditForm from '@components/tickets/TicketEditForm.vue'
import TicketHistoryTable from '@components/tickets/TicketHistoryTable.vue'
import CustomerAttachments from '@components/customers/CustomerAttachments.vue'
import TicketShowAdditionalFields from '@components/tickets/TicketShowAdditionalFields.vue'
import TicketDataTable from "@components/tickets/ebay/TicketDataTable.vue";
import TicketPaymentsDetails from "@components/tickets/ebay/TicketPaymentsDetails.vue";
import ScrubReasonEditForm from '@components/recall/ScrubReasonEditForm.vue';
import TicketListFiltered from '@components/tickets/TicketListFiltered.vue';

export default {
  name: 'TicketBody',
  components: {
    ScrubReasonEditForm,
    TicketPaymentsDetails,
    TicketDataTable,
    CustomerAttachments,
    TicketHistoryTable,
    TicketShowAdditionalFields,
    TicketEditForm,
    ListItem,
    TicketListFiltered,
  },
  data() {
    return {
      associatedTickets: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      ticket: 'current',
    }),
    ...mapState('ticketsActivities', {
      activity: 'current',
    }),
    ...mapState('customers', {
      customer: 'current',
    }),
    ...mapState('customersUsers', {
      user: 'current',
    }),
    ...mapState('customersLocations', {
      location: 'current',
    }),
    ...mapState('plansSubscriptions', {
      subscription: 'current',
    }),
    ...mapState('externalData', {
      externalData: 'current',
    }),
    ...mapGetters('tickets', {
      isTicketBlocked: 'isTicketBlocked',
    }),
    isHandleSubscriptionShown() {
      return (
        this.ticket.extra?.plan_activation &&
        this.canUser('plans_subscriptions', 'update')
      )
    },
    showAdditionalFields() {
      return this.ticket.additional_fields.length > 0
    },
    recallMenuIsEnabled() {
      if (!this.canUser('recall_debt_records_mp', 'update')) return false;
      if (this.ticket.status !== 'RECALL') return false;
      return this.ticket.resolution === 'Old';
    },
    ticketStatusMenuIsEnabled() {
      if (!this.canUser('tickets', 'update')) return false;
      if (this.isTicketBlocked) {
        if (this.userRole !== 'callcenter_manager') return false;
        return this.ticket.status === 'SOSPESA';
      }
      return true;
    }
  },
  watch: {
    async externalData(newValue, oldValue) {
      if (newValue.identificativo_ebay !== oldValue.identificativo_ebay) {
        this.associatedTickets = await this.getAssociatedTickets() || [];
        this.setTicketsFilters({ external_id: this.associatedTickets });
        this.setTicketsScopes(['customer', 'activity', 'sla', 'priority']);
      }
    }
  },
  beforeDestroy() {
    this.resetTicketsFilters();
    this.setTicketsScopes([]);
  },
  methods: {
    ...mapActions('externalData', {
      getAssociatedTickets: 'getAssociatedTickets',
    }),
    ...mapMutations('tickets', {
      setTicketsFilters: 'SET_FILTERS',
      resetTicketsFilters: 'RESET_FILTERS',
      setTicketsScopes: 'SET_SCOPES',
    }),
  },
}
</script>

<style scoped></style>
